.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white; /* Ensure the navbar is above the map */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#mapping {
    padding-top: 120px; /* Adjust this value according to your navbar height */
}

.leaflet-container {
    z-index: 10; /* Ensure the map is below the navbar */
}

.leaflet-top.leaflet-left .leaflet-control {
    z-index: 20; /* Ensure map controls are above the map but below the navbar */
}

/* Additional styles for reset button */
.leaflet-top.leaflet-left .leaflet-control button {
    background-color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    z-index: 700; /* Higher than map elements but lower than navbar */
}

/* Responsive map container */
@media (max-width: 768px) {
    #mapping {
        padding-top: 90px;
    }

    .leaflet-container {
        height: 50vh; /* Adjust map height on mobile */
    }
}
