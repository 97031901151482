@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
  background-color: #a3d9a5; /* Soft green */
}

/* Ensure the hero section resizes correctly on mobile */
@media (max-width: 768px) {
  .hero {
    background-position: center;
  }
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
  background-color: rgba(50, 150, 50, 0.8); /* Dark green overlay */
}

/* Adjust the padding and margins for better mobile view */
@media (max-width: 768px) {
  .footer {
    padding: 20px;
    background-position: center;
  }
}

.cta {
  background: url('./toadtracker_resources/main_page/cta-image.jpg') 
              fixed center center;
  background-size: cover;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: #3a5f3a; /* Darker green for navbar */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Ensure the navbar text size is responsive */
@media (max-width: 768px) {
  .navbar h1 {
    font-size: 1.5rem;
  }

  .navbar p {
    font-size: 0.75rem;
  }
}

/* Subtle Flashy Text Effect */
.flashy-text {
  background: linear-gradient(to right, #166534, #15803d, #16a34a, #22c55e); /* Subtle green gradient */
  background-size: 200% auto;
  color: #15803d; /* Fallback color */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 8s linear infinite; /* Slower animation for subtlety */
}

@keyframes shine {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}

/* Subtle Shine Effect for Get in Touch Button */
.shine-effect {
  background: linear-gradient(to right, #15803d, #166534, #15803d);
  background-size: 200% auto;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-position 0.3s ease;
  animation: buttonShine 8s linear infinite;
}

@keyframes buttonShine {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

.shine-effect:hover {
  background-position: 0 0; /* Ensure the gradient effect stays smooth */
  transform: scale(1.05); /* Slight scale effect on hover */
}

/* Add this CSS to your global styles or specific component CSS file */
.ellipse-frame {
  border-radius: 80% / 50%; /* Increasing the second value will make the ellipse taller */
  overflow: hidden;
}

/* Ensure responsive hero section layout on mobile */
@media (max-width: 768px) {
  .hero .flex {
    flex-direction: column;
    align-items: center;
  }
}
